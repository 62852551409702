import React from 'react'
import styled from 'styled-components'
import { Box, Title3, Large, colors } from 'js-components'
import { Section, WrapperBackground, WrapperSolid } from '../../components/Wrapper'
import { Helmet } from 'react-helmet'
import { PageTitle, PageTitle2 } from '../../components/PageTitle'
import Default from '../../components/Layouts/Default';

import ItMonitoring from '../images/itMonitoring.svg'
import TeamDashOnLaptop from '../images/teamDashOnLaptop.svg'
import PrecisionAction from '../images/precisionAction.png'

const SolutionsPage = () => (
  <Default>
    <Helmet>
      <title>Truly | Revenue Insights For Your Business</title>
    </Helmet>

    <WrapperBackground
      color={colors.trulyDark}
      background='serverRoom'
    >
      <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
        <Box width="1">
          <PageTitle2 as="h2" color={colors.accentFog} bold mb="1.5rem" center>
          Create an Unfair Data Advantage
          </PageTitle2>
          <PageTitle as="p" color={colors.white} mb="1.5rem" center>
            Outmaneuver the competition through better information sharing.
          </PageTitle>
        </Box>
      </Section>
    </WrapperBackground>

    <WrapperSolid>
      <Section flexDirection="column" my={['1rem', '2rem', '5rem']}>
        <Box width={[1, 2/3]} pb="2rem">
          <WidthImg src={ItMonitoring} alt="Monitoring"/>
        </Box>
        <Box width={[1, 1/2]}>
          <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
          'ZERO TOUCH' CRM ACTIVITY SYNC
          </Title3>
          <Large as="p" color={colors.trulyDark} mb="1.5rem" center>
            45% of data doesn't make it into CRM, because all sales tools require at least one user action to log activity data.
            <br/><br/>
            We offer the ONLY integration that gets 100% of your data into CRM, and our intelligent algorithms act as an 'assistant' in ensuring data is attributed correctly based on dozens of data points.
            <br/><br/>
            This ensures every system of record (CRM, helpdesk, data warehouse) has a true representation of your customer engagement.
          </Large>
        </Box>
      </Section>
    </WrapperSolid>

    <WrapperSolid color='#292929'>
      <Section flexDirection={['column', 'row']} my={['1rem', '2rem', '5rem']}>
        <Box width={[1/2, 1/2]} pb="2rem">
          <WidthImg src={TeamDashOnLaptop} alt="Data Attribution"/>
        </Box>
        <Box width={[1, 1/2]}>
          <Title3 as="h2" color={colors.accentFog} bold mb="1.5rem" center>
            BETTER DATA ATTRIBUTION
          </Title3>
          <Large as="p" color={colors.white} mb="1.5rem" center>
            Only 27% of activity data that makes it into the CRM is attributed to the right Account, Contact and Opportunity Objects.  
            <br/><br/>
            Truly's intelligent algorithms act as a 'virtual assistant', connecting dozens of data points across communication history, calendar and CRM to ensure activity is attributed to the right things.
            <br/><br/>
            This makes reporting more actionable, and data more discoverable in the CRM.
          </Large>
        </Box>
      </Section>
    </WrapperSolid>

    <WrapperSolid>
      <Section flexDirection={['column', 'row']} my={['1rem', '2rem', '5rem']}>
        <Box width={[1/2, 1/2]} pb="2rem">
          <WidthImg src={PrecisionAction} alt="Less Data Cleanup"/>
        </Box>
        <Box width={[1, 1/2]}>
          <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
           LESS DATA CLEANUP = FASTER DECISIONS
          </Title3>
          <Large as="p" color={colors.trulyDark} mb="1.5rem" center>
            Today's management teams have to spend hours exporting, cleaning and backfilling data to have their CRM reporting make sense.
            <br/><br/>
            With better data, you'll be able to get more resources and empower managemers to make decisions faster, and with higher confidence.            
          </Large>
        </Box>
      </Section>
    </WrapperSolid>
  </Default>
)

export default SolutionsPage


const WidthImg = styled.img`
  display: block;
  max-width: 100%;
`